import { Fragment } from "react";
import { Helmet } from "react-helmet";
import classes from './Live.module.css';
import mainclasses from '../../style.module.css';
const Live = () => {

    return (
        <>
            <Helmet>
                <title>Rajshree Lottery Live Draws | Rajshree Lottery</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Here you can watch rajshree lottery results live. Get live results of rajshree lottery." />
            </Helmet>
            <div className="flex-fill">
                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col-md-8 mx-auto text-center">
                            <h2 className={mainclasses.head}>Live Draw</h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            {/* <!--<iframe width="100%" height="500" src="https://www.youtube.com/embed/uV5JGOyzKR8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>--> */}
                            <iframe width="100%" height="500" title="YouTube video player" className={classes.livevideo} src="https://www.youtube.com/embed/live_stream?channel=UCUnYPWIQ6q-Oey5qOsZR5BA" frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-md-11 mx-auto text-center">
                            <h2 className={mainclasses.head}>Old Results</h2>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4">
                    <div className="row">
                        <div className="col-md-11 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 mt-3">
                                    <iframe width="100%" height="315" title="YouTube video player" src="https://www.youtube.com/embed/?list=PLScmk_YFWuBO2qLbXNca6QCRX4M_tqq5T" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                                <div className="col-lg-4 mt-3">
                                    <iframe width="100%" height="315" title="YouTube video player" src="https://www.youtube.com/embed/?list=PLScmk_YFWuBO2qLbXNca6QCRX4M_tqq5T" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                                <div className="col-lg-4 mt-3">
                                    <iframe width="100%" height="315" title="YouTube video player" src="https://www.youtube.com/embed/?list=PLScmk_YFWuBO2qLbXNca6QCRX4M_tqq5T" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-lg-5 mx-auto text-center">
                            <h3 className={mainclasses.head1}>Rajshree Lottery Live Draw | Rajshree Lottery Result Live</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Live;